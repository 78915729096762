html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

strong {
  font-family: 'Avenir-Medium';
}

.ame {
  color: #F85133;
}

a:link, a:visited {
  color: #F85133;
  text-decoration: none;
}

a:hover, a:active {
  text-decoration: underline;
}
.section-splash {
    padding-top: 0;
    margin-top: 0;
}

.splash-screen{
    background-color: green;
}

.waves-container {
    min-height: 100%;
    background:radial-gradient(ellipse at top, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #3385eb 100%);
    /*background: url("/img/team.png");*/
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    overflow: hidden;
}

.ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(248, 81, 51);
}

.head {
    margin-top: 5%;
    position: relative;
}

img.logo {
    width: 100%;
    max-height: 260px;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
}

@media only screen and (max-width : 1200px) {
    .ocean {
        height: 0 !important;
    }

    img.logo {
        max-height: 180px;
        top: 56px;
    }

    .waves-container h1 {
        margin-top: -60px;
        font-size: 54pt !important;
    }

    .waves-container h2 {
        margin-top: 110px;
        font-size: 22pt !important;
    }

    .waves-container h1 span.w {
        font-size: 64pt !important;
    }

    .head {
        margin-top: 8% !important;
    }
}

@media only screen and (max-width : 992px) {
    .ocean {
        height: 5% !important;
    }

    img.logo {
        max-height: 180px;
        top: 56px;
    }

    .waves-container h1 {
        margin-top: -60px;
        font-size: 54pt !important;
    }

    .waves-container h2 {
        margin-top: 110px;
        font-size: 22pt !important;
    }

    .waves-container h1 span.w {
        font-size: 64pt !important;
    }
}

@media only screen and (max-width : 768px) {
    .ocean {
        height: 5% !important;
    }

    img.logo {
        max-height: 210px !important;
    }

    .waves-container h1 {
        margin-top: -40px;
        font-size: 65pt !important;
    }

    .waves-container h2 {
        font-size: 25pt !important;
        margin-top: 150px !important;
        visibility: hidden;
    }

    .waves-container h1 span.w {
        font-size: 75pt !important;
    }

    .head {
        margin-top: 16% !important;
    }
}

@media only screen and (max-width : 480px) {
    .ocean {
        height: 0 !important;
    }

    .head {
        margin-top: 24% !important;
    }

    img.logo {
        top: 28px;
    }

    .waves-container h1{
        font-size: 29pt !important;
    }

    .waves-container h2{
        font-size: 20pt !important;
        margin-top: 120px !important;
    }

    .waves-container h1 span.w {
        font-size: 39pt !important;
    }
}

.waves-container h1 {
    width: 100%;
    text-align: center;
    font-size: 60pt;
    font-family: BebasNeueBold, "Bebas Neue";
    font-weight: 700;
}

.waves-container h1 span.w {
    font-size: 70pt;
}

.waves-container h2 {
    width: 100%;
    text-align: center;
    font-size: 25pt;
    font-family: BebasNeueBold, "Bebas Neue";
    font-weight: 700;
    margin-top: 130px;
}

.wave {
    position: absolute;
    top: -319.76px;
    width: 4800px;
    height: 319.76px;
    -webkit-animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
            animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
    top: -296.76px;
    -webkit-animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.825s infinite, swell 5s ease -1.25s infinite;
            animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.825s infinite, swell 5s ease -1.25s infinite;
    opacity: 1;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url(/static/media/Avenir-Roman.97d02245.eot);
    src: local('../fonts/Avenir Roman'), local('../fonts/Avenir-Roman'),
    url(/static/media/Avenir-Roman.97d02245.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Avenir-Roman.9cee5697.woff2) format('woff2'),
    url(/static/media/Avenir-Roman.28d50250.woff) format('woff'),
    url(/static/media/Avenir-Roman.b8629568.ttf) format('truetype'),
    url(/static/media/Avenir-Roman.f60f0132.svg#Avenir-Roman) format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url(/static/media/Avenir-Medium.08eaee7e.eot);
    src: local('../fonts/Avenir Medium'), local('../fonts/Avenir-Medium'),
    url(/static/media/Avenir-Medium.08eaee7e.eot?#iefix) format('embedded-opentype'),
    url(/static/media/Avenir-Medium.78b3d71c.woff2) format('woff2'),
    url(/static/media/Avenir-Medium.8493a6ce.woff) format('woff'),
    url(/static/media/Avenir-Medium.85fc4658.ttf) format('truetype'),
    url(/static/media/Avenir-Medium.9dcdf9b1.svg#Avenir-Medium) format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueBold';
    src: url(/static/media/BebasNeueBold.f25e527a.eot);
    src: local('../fonts/BebasNeueBold'),
    url(/static/media/BebasNeueBold.f25e527a.eot?#iefix) format('embedded-opentype'),
    url(/static/media/BebasNeueBold.018c723b.woff2) format('woff2'),
    url(/static/media/BebasNeueBold.27d35d9d.woff) format('woff'),
    url(/static/media/BebasNeueBold.eda10922.ttf) format('truetype'),
    url(/static/media/BebasNeueBold.abb4663a.svg#BebasNeueBold) format('svg');
    font-weight: bold;
    font-style: normal;
}

@-webkit-keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@-webkit-keyframes swell {
    0%, 100% {
        -webkit-transform: translate3d(0,-25px,0);
                transform: translate3d(0,-25px,0);
    }
    50% {
        -webkit-transform: translate3d(0,5px,0);
                transform: translate3d(0,5px,0);
    }
}

@keyframes swell {
    0%, 100% {
        -webkit-transform: translate3d(0,-25px,0);
                transform: translate3d(0,-25px,0);
    }
    50% {
        -webkit-transform: translate3d(0,5px,0);
                transform: translate3d(0,5px,0);
    }
}

svg {
    height: 319.76px;
}



.wave1 #Layer1 #point1, .wave1 #Layer1 [data-name='point1'] {
    display: none;
}
.wave1 #Layer1 #point3, .wave1 #Layer1 [data-name='point3'] {
    display: none;
}
.wave1 #Layer2 #point1, .wave1 #Layer2 [data-name='point1'] {
    display: none;
}
.wave1 #Layer2 #point3, .wave1 #Layer2 [data-name='point3'] {
    display: none;
}
.wave1 #Layer3 #point1, .wave1 #Layer3 [data-name='point1'] {
    display: none;
}
.wave1 #Layer3 #point3, .wave1 #Layer3 [data-name='point3'] {
    display: none;
}

.wave2 #Layer1 #point2, .wave2 #Layer1 [data-name='point2'] {
    display: none;
}
.wave2 #Layer1 #point4, .wave2 #Layer1 [data-name='point4'] {
    display: none;
}
.wave2 #Layer2 #point2, .wave2 #Layer2 [data-name='point2'] {
    display: none;
}
.wave2 #Layer2 #point4, .wave2 #Layer2 [data-name='point4'] {
    display: none;
}
.wave2 #Layer3 #point2, .wave2 #Layer3 [data-name='point2'] {
    display: none;
}
.wave2 #Layer3 #point4, .wave2 #Layer3 [data-name='point4'] {
    display: none;
}






/**/
.cont-testo {
}

.testo {
}


#point1-2 {
    border: 1px solid red;
    background-color: yellow;
}

.bounce {
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

.chevron {
    position: absolute;
    width: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}
.App {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .head {
  text-transform: uppercase;
}

h1, h2, h3{
  font-weight: 700;
}

section{
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}

section h2{
  padding-bottom: 1.5rem;
  font-size: 3rem;
}

section p {
  font-size: 1.3rem;
}

video {
  display: inline-block;
}

@-webkit-keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes App-logo-spin {
  from { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  to { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@media (max-width: 991.98px) {
  section {
    min-height: 50%;
  }

  section h1, section h2, section h3{
    text-align: center !important;
  }

  section p {
    text-align: center !important;
  }

  section video {
    margin: 0 auto;
    display: block;
    margin-top: 50px;
  }

  img.img-fluid {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }
}

.btn {
    padding: 10px;
}

.btn-primary {
    background-color: #F85133;
    color: white !important;
    outline: 0;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  
.btn-secondary {
    background-color: #AAAAAA;
    color: white !important;
    outline: 0;
    border: 0;
    margin-left: 5px;
}

.btn-primary:hover {
    background-color: #333333;
    color: white !important;
    outline: 0;
    border: 0;
}

.btn-secondary:hover {
    background-color: #888888;
    color: white !important;
    outline: 0;
    border: 0;
    margin-left: 5px;
}

form label {
    margin-bottom: -0.2rem;
}

.section-text h2, .section-contact-us h2 {
    position: relative;
}

.section-text h2:after, .section-contact-us h2:after {
    content: "";
    border: 3px solid #F85133;
    position: absolute;
    bottom: 17px;
    width: 100%;
    left: 0;
    z-index: -1;
    opacity: .1;
}

section.section-claim {
}

.claim-content{
  min-height: 100%;
  height: 100vh;
}

.claim-content .main-content{
  margin: 0 auto;
}

.claim-content .main-content h1{
  margin-bottom: 2rem;
}

.claim-content .head{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}

@media (max-width: 991.98px) {
  .claim-content {
    height: 50vh;
  }
}

section.section-text {
  text-align: center;
  color: black;
  /*padding-top: 16vh;*/
  /*padding-bottom: 16vh;*/
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-text h2 {
  font-family: 'BebasNeueBold';
}

section.section-text p.text {
  font-family: 'Avenir-Roman';
}

section#iNostriProgetti {
    padding-bottom: 1rem;
}
section.section-video-text {
  color: black;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-video-text h2 {
  font-family: 'BebasNeueBold';
}

section.section-video-text p.text {
  font-family: 'Avenir-Roman';
}

section.section-video-text video {
  max-width: 100%;
}
section.section-video-text-reverse {
  color: black;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-video-text-reverse h2 {
  font-family: 'BebasNeueBold';
}

section.section-video-text-reverse p.text {
  font-family: 'Avenir-Roman';
}

section.section-video-text-reverse video {
  max-width: 100%;
}
section.section-image-text {
  color: black;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text h2 {
  font-family: 'BebasNeueBold';
}

section.section-image-text p.text {
  font-family: 'Avenir-Roman';
}
section.section-image-text-reverse {
  color: black;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text-reverse h2 {
  font-family: 'BebasNeueBold';
}

section.section-image-text-reverse p.text {
  font-family: 'Avenir-Roman';
}
section.section-contact-us {
  height: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 6vh;
  padding-bottom: 0;
}

label {
  display: block;
}

textarea {
  min-height: 205px;
}

button {
  margin: 0;
}

input[type=checkbox] {
  margin-right: 5px;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-contact-us h2 {
  text-align: center;
}

section.section-contact-us p {
  text-align: center;
}

section.section-contact-us h2 {
  font-family: 'BebasNeueBold';
}

section.section-contact-us p.text {
  font-family: 'Avenir-Roman';
}

section.section-contact-us label {
  font-family: 'Avenir-Roman';
  margin-top: -3px;
}

section.section-contact-us input {
  font-family: 'Avenir-Roman';
}

section.section-contact-us select {
  font-family: 'Avenir-Roman';
}

section.section-contact-us textarea {
  font-family: 'Avenir-Roman';
}

section.section-contact-us button {
  font-family: 'Avenir-Roman';
}

section.section-contact-us a:link, section.section-contact-us a:visited {
  color: #333333;
}

section.section-contact-us a:hover, section.section-contact-us a:active {
  color: #F85133;
  text-decoration: none;
}

section.section-contact-us .form-group {
  padding-left: 0;
}

section.section-contact-us p {
  padding-bottom: 20px;
}
footer {
  background: rgb(248,81,51); /* Old browsers */
  /*background: -moz-linear-gradient(left, rgba(248,81,51,1) 0%, rgba(242,127,106,1) 100%); !* FF3.6-15 *!*/
  /*background: -webkit-linear-gradient(left, rgba(248,81,51,1) 0%,rgba(242,127,106,1) 100%); !* Chrome10-25,Safari5.1-6 *!*/
  /*background: linear-gradient(to right, rgba(248,81,51,1) 0%,rgba(242,127,106,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85133', endColorstr='#f27f6a',GradientType=1 ); !* IE6-9 *!*/

  color: white;
}


footer h2 {
  font-family: 'BebasNeueBold';
}

footer p {
  font-family: 'Avenir-Roman';
}

footer a:link, footer a:visited {
  font-family: 'Avenir-Roman';
  color: white;
}

footer a:hover, footer a:active {
  font-family: 'Avenir-Roman';
  color: #333333;
  text-decoration: none;
}

footer .credits img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
}

footer .credits p {
  text-align: center;
}

footer .credits p.legal {
  padding-top: 30px;
  font-weight: bold;
  font-size: 12pt;
  line-height: 11px;
}

footer .credits p.piva {
  font-size: 12pt;
  line-height: 2px;
}

footer .credits p.link {
  font-size: 12pt;
  font-weight: bold;
  line-height: 48px;
}

@media (max-width: 767.98px) {
  footer .credits p.legal {
    line-height: 25px;
  }

  footer .credits p.piva {
    line-height: 25px;
  }
}
.simple-photo {
	padding: 20px;
}

.simple-photo img {
	max-width: 100%;
}

.simple-photo h3 {
	margin-top: 30px;
	font-family: 'Avenir-Roman';
	text-align: left;
	font-weight: bold;
}

.simple-photo p {
	font-family: 'Avenir-Roman';
	text-align: left;
}
.image-box{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media(max-width: 767.98px) {
}

