section.section-image-text {
  color: black;
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-image-text h2 {
  font-family: 'BebasNeueBold';
}

section.section-image-text p.text {
  font-family: 'Avenir-Roman';
}