section.section-claim {
}

.claim-content{
  min-height: 100%;
  height: 100vh;
}

.claim-content .main-content{
  margin: 0 auto;
}

.claim-content .main-content h1{
  margin-bottom: 2rem;
}

.claim-content .head{
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem;
}

@media (max-width: 991.98px) {
  .claim-content {
    height: 50vh;
  }
}
