section.section-text {
  text-align: center;
  color: black;
  /*padding-top: 16vh;*/
  /*padding-bottom: 16vh;*/
}

.limita{
  max-width: 900px;
  margin: 0 auto;
}

section.section-text h2 {
  font-family: 'BebasNeueBold';
}

section.section-text p.text {
  font-family: 'Avenir-Roman';
}

section#iNostriProgetti {
    padding-bottom: 1rem;
}