.simple-photo {
	padding: 20px;
}

.simple-photo img {
	max-width: 100%;
}

.simple-photo h3 {
	margin-top: 30px;
	font-family: 'Avenir-Roman';
	text-align: left;
	font-weight: bold;
}

.simple-photo p {
	font-family: 'Avenir-Roman';
	text-align: left;
}