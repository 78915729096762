html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

strong {
  font-family: 'Avenir-Medium';
}

.ame {
  color: #F85133;
}

a:link, a:visited {
  color: #F85133;
  text-decoration: none;
}

a:hover, a:active {
  text-decoration: underline;
}