.App {
  font-family: 'Roboto Slab', serif;
  font-weight: 400;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

.App .head {
  text-transform: uppercase;
}

h1, h2, h3{
  font-weight: 700;
}

section{
  height: 100%;
  min-height: 60%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 6% 0;
}

section h2{
  padding-bottom: 1.5rem;
  font-size: 3rem;
}

section p {
  font-size: 1.3rem;
}

video {
  display: inline-block;
}

@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@media (max-width: 991.98px) {
  section {
    min-height: 50%;
  }

  section h1, section h2, section h3{
    text-align: center !important;
  }

  section p {
    text-align: center !important;
  }

  section video {
    margin: 0 auto;
    display: block;
    margin-top: 50px;
  }

  img.img-fluid {
    max-width: 80%;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
  }
}

.btn {
    padding: 10px;
}

.btn-primary {
    background-color: #F85133;
    color: white !important;
    outline: 0;
    border: 0;
    text-transform: uppercase;
    font-weight: bold;
  }
  
.btn-secondary {
    background-color: #AAAAAA;
    color: white !important;
    outline: 0;
    border: 0;
    margin-left: 5px;
}

.btn-primary:hover {
    background-color: #333333;
    color: white !important;
    outline: 0;
    border: 0;
}

.btn-secondary:hover {
    background-color: #888888;
    color: white !important;
    outline: 0;
    border: 0;
    margin-left: 5px;
}

form label {
    margin-bottom: -0.2rem;
}

.section-text h2, .section-contact-us h2 {
    position: relative;
}

.section-text h2:after, .section-contact-us h2:after {
    content: "";
    border: 3px solid #F85133;
    position: absolute;
    bottom: 17px;
    width: 100%;
    left: 0;
    z-index: -1;
    opacity: .1;
}
