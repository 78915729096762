.section-splash {
    padding-top: 0;
    margin-top: 0;
}

.splash-screen{
    background-color: green;
}

.waves-container {
    min-height: 100%;
    background:radial-gradient(ellipse at top, rgba(255,254,234,1) 0%, rgba(255,254,234,1) 35%, #3385eb 100%);
    /*background: url("/img/team.png");*/
    /*background-position: center center;*/
    /*background-repeat: no-repeat;*/
    /*background-size: cover;*/
    overflow: hidden;
}

.ocean {
    height: 5%;
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    background: rgb(248, 81, 51);
}

.head {
    margin-top: 5%;
    position: relative;
}

img.logo {
    width: 100%;
    max-height: 260px;
    margin-bottom: 10px;
    position: absolute;
    top: 0;
}

@media only screen and (max-width : 1200px) {
    .ocean {
        height: 0 !important;
    }

    img.logo {
        max-height: 180px;
        top: 56px;
    }

    .waves-container h1 {
        margin-top: -60px;
        font-size: 54pt !important;
    }

    .waves-container h2 {
        margin-top: 110px;
        font-size: 22pt !important;
    }

    .waves-container h1 span.w {
        font-size: 64pt !important;
    }

    .head {
        margin-top: 8% !important;
    }
}

@media only screen and (max-width : 992px) {
    .ocean {
        height: 5% !important;
    }

    img.logo {
        max-height: 180px;
        top: 56px;
    }

    .waves-container h1 {
        margin-top: -60px;
        font-size: 54pt !important;
    }

    .waves-container h2 {
        margin-top: 110px;
        font-size: 22pt !important;
    }

    .waves-container h1 span.w {
        font-size: 64pt !important;
    }
}

@media only screen and (max-width : 768px) {
    .ocean {
        height: 5% !important;
    }

    img.logo {
        max-height: 210px !important;
    }

    .waves-container h1 {
        margin-top: -40px;
        font-size: 65pt !important;
    }

    .waves-container h2 {
        font-size: 25pt !important;
        margin-top: 150px !important;
        visibility: hidden;
    }

    .waves-container h1 span.w {
        font-size: 75pt !important;
    }

    .head {
        margin-top: 16% !important;
    }
}

@media only screen and (max-width : 480px) {
    .ocean {
        height: 0 !important;
    }

    .head {
        margin-top: 24% !important;
    }

    img.logo {
        top: 28px;
    }

    .waves-container h1{
        font-size: 29pt !important;
    }

    .waves-container h2{
        font-size: 20pt !important;
        margin-top: 120px !important;
    }

    .waves-container h1 span.w {
        font-size: 39pt !important;
    }
}

.waves-container h1 {
    width: 100%;
    text-align: center;
    font-size: 60pt;
    font-family: BebasNeueBold, "Bebas Neue";
    font-weight: 700;
}

.waves-container h1 span.w {
    font-size: 70pt;
}

.waves-container h2 {
    width: 100%;
    text-align: center;
    font-size: 25pt;
    font-family: BebasNeueBold, "Bebas Neue";
    font-weight: 700;
    margin-top: 130px;
}

.wave {
    position: absolute;
    top: -319.76px;
    width: 4800px;
    height: 319.76px;
    animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) infinite;
    transform: translate3d(0, 0, 0);
}

.wave:nth-of-type(2) {
    top: -296.76px;
    animation: wave 25s cubic-bezier( 0.36, 0.45, 0.63, 0.53) -.825s infinite, swell 5s ease -1.25s infinite;
    opacity: 1;
}

@font-face {
    font-family: 'Avenir-Roman';
    src: url('../fonts/Avenir-Roman.eot');
    src: local('../fonts/Avenir Roman'), local('../fonts/Avenir-Roman'),
    url('../fonts/Avenir-Roman.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Avenir-Roman.woff2') format('woff2'),
    url('../fonts/Avenir-Roman.woff') format('woff'),
    url('../fonts/Avenir-Roman.ttf') format('truetype'),
    url('../fonts/Avenir-Roman.svg#Avenir-Roman') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Avenir-Medium';
    src: url('../fonts/Avenir-Medium.eot');
    src: local('../fonts/Avenir Medium'), local('../fonts/Avenir-Medium'),
    url('../fonts/Avenir-Medium.eot?#iefix') format('embedded-opentype'),
    url('../fonts/Avenir-Medium.woff2') format('woff2'),
    url('../fonts/Avenir-Medium.woff') format('woff'),
    url('../fonts/Avenir-Medium.ttf') format('truetype'),
    url('../fonts/Avenir-Medium.svg#Avenir-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'BebasNeueBold';
    src: url('../fonts/BebasNeueBold.eot');
    src: local('../fonts/BebasNeueBold'),
    url('../fonts/BebasNeueBold.eot?#iefix') format('embedded-opentype'),
    url('../fonts/BebasNeueBold.woff2') format('woff2'),
    url('../fonts/BebasNeueBold.woff') format('woff'),
    url('../fonts/BebasNeueBold.ttf') format('truetype'),
    url('../fonts/BebasNeueBold.svg#BebasNeueBold') format('svg');
    font-weight: bold;
    font-style: normal;
}

@keyframes wave {
    0% {
        margin-left: 0;
    }
    100% {
        margin-left: -1600px;
    }
}

@keyframes swell {
    0%, 100% {
        transform: translate3d(0,-25px,0);
    }
    50% {
        transform: translate3d(0,5px,0);
    }
}

svg {
    height: 319.76px;
}



.wave1 #Layer1 #point1, .wave1 #Layer1 [data-name='point1'] {
    display: none;
}
.wave1 #Layer1 #point3, .wave1 #Layer1 [data-name='point3'] {
    display: none;
}
.wave1 #Layer2 #point1, .wave1 #Layer2 [data-name='point1'] {
    display: none;
}
.wave1 #Layer2 #point3, .wave1 #Layer2 [data-name='point3'] {
    display: none;
}
.wave1 #Layer3 #point1, .wave1 #Layer3 [data-name='point1'] {
    display: none;
}
.wave1 #Layer3 #point3, .wave1 #Layer3 [data-name='point3'] {
    display: none;
}

.wave2 #Layer1 #point2, .wave2 #Layer1 [data-name='point2'] {
    display: none;
}
.wave2 #Layer1 #point4, .wave2 #Layer1 [data-name='point4'] {
    display: none;
}
.wave2 #Layer2 #point2, .wave2 #Layer2 [data-name='point2'] {
    display: none;
}
.wave2 #Layer2 #point4, .wave2 #Layer2 [data-name='point4'] {
    display: none;
}
.wave2 #Layer3 #point2, .wave2 #Layer3 [data-name='point2'] {
    display: none;
}
.wave2 #Layer3 #point4, .wave2 #Layer3 [data-name='point4'] {
    display: none;
}






/**/
.cont-testo {
}

.testo {
}


#point1-2 {
    border: 1px solid red;
    background-color: yellow;
}

.bounce {
    -moz-animation: bounce 3s infinite;
    -webkit-animation: bounce 3s infinite;
    animation: bounce 3s infinite;
}
@-moz-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@-webkit-keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}
@keyframes bounce {
    0%, 20%, 50%, 80%, 100% {
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -webkit-transform: translateY(0);
        transform: translateY(0);
    }
    40% {
        -moz-transform: translateY(-30px);
        -ms-transform: translateY(-30px);
        -webkit-transform: translateY(-30px);
        transform: translateY(-30px);
    }
    60% {
        -moz-transform: translateY(-15px);
        -ms-transform: translateY(-15px);
        -webkit-transform: translateY(-15px);
        transform: translateY(-15px);
    }
}

.chevron {
    position: absolute;
    width: 50px;
    bottom: 50px;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}