footer {
  background: rgb(248,81,51); /* Old browsers */
  /*background: -moz-linear-gradient(left, rgba(248,81,51,1) 0%, rgba(242,127,106,1) 100%); !* FF3.6-15 *!*/
  /*background: -webkit-linear-gradient(left, rgba(248,81,51,1) 0%,rgba(242,127,106,1) 100%); !* Chrome10-25,Safari5.1-6 *!*/
  /*background: linear-gradient(to right, rgba(248,81,51,1) 0%,rgba(242,127,106,1) 100%); !* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ *!*/
  /*filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f85133', endColorstr='#f27f6a',GradientType=1 ); !* IE6-9 *!*/

  color: white;
}


footer h2 {
  font-family: 'BebasNeueBold';
}

footer p {
  font-family: 'Avenir-Roman';
}

footer a:link, footer a:visited {
  font-family: 'Avenir-Roman';
  color: white;
}

footer a:hover, footer a:active {
  font-family: 'Avenir-Roman';
  color: #333333;
  text-decoration: none;
}

footer .credits img {
  max-height: 100px;
  margin: 0 auto;
  display: block;
}

footer .credits p {
  text-align: center;
}

footer .credits p.legal {
  padding-top: 30px;
  font-weight: bold;
  font-size: 12pt;
  line-height: 11px;
}

footer .credits p.piva {
  font-size: 12pt;
  line-height: 2px;
}

footer .credits p.link {
  font-size: 12pt;
  font-weight: bold;
  line-height: 48px;
}

@media (max-width: 767.98px) {
  footer .credits p.legal {
    line-height: 25px;
  }

  footer .credits p.piva {
    line-height: 25px;
  }
}